export const rubrosHistoricos = [
    {
        "anio" : "2024",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/zip/HISTORICO_2T_2024.rar"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/zip/2T_Historico_2024.rar"
            },
            {
                "trimestre" : "Tercero Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/zip/3T_Historico_2024.rar"
            },/*
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/zip/Historico_4_2023.rar"
            }*/
        ]
    },
    {
        "anio" : "2023",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/1er-2023.rar"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/2er-2023.rar"
            },
            {
                "trimestre" : "Tercero Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/3o_Historico_2023.rar"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/EJERCICIO_2024/zip/Historico_4_2023.rar"
            }
        ]
    },
    {
        "anio" : "2022",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/1_TRIMESTRE_2022.rar"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/3_TRIMESTRE_2022.rar"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/HISTORICO_4°TRIMESTRE_2022.rar"
            }
        ]
    },
    {
        "anio" : "2021",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/PRIMER_TRIMESTRE_2021.rar"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/SEGUNDO_TRIMESTRE_2021.rar"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/3Trimestre_2021.rar"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/Cuarto_Trimestre_2021.rar"
            }
        ]
    },
    {
        "anio" : "2020",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/Primer_Trimestre_2020.rar"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/Segundo_Trimestre_2020.rar"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/Tercer_Trimestre_2020.rar"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/Cuarto_Trimestre_2020.rar"
            }
        ]
    },
    {
        "anio" : "2019",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/PRIMER_TRIMESTRE_2019.rar"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/"
            }
        ]
    },
    {
        "anio" : "2018",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/SITMAH1ERTRIMESTRE2018.rar"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/SITMAH2DOSEMESTRE2018.rar"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/SITMAH3ERTRIMESTRE2018.rar"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/TMasivo/armonizacionC/EJERCICIO_2023/zip/SITMAH4TOTRIMESTRE.zip"
            }
        ]
    },
    {
        "anio" : "2015 - 2017",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Plataforma Nacional",
                "url" : "https://consultapublicamx.plataformadetransparencia.org.mx/vut-web/faces/view/consultaPublica.xhtml#inicio"
            }
        ]
    },
    {
        "anio" : "Información Histórica Art. 22",
        "descripcion" : "En base al Acuerdo Gubernamental por el que se adscribe la Subsecretaría de Transporte y se sectorizan los Organismos Públicos Descentralizados Sistema Integrado de Transporte Masivo de Hidalgo y Sistema de Transporte Convencional de Hidalgo, a la Secretaría de Movilidad y Transporte, publicado el 06 de Marzo del 2017 en el Periódico Oficial del Estado de Hidalgo. La Secretaría de Movilidad y Transporte se crea por Decreto No. 166 que Reforma, Adiciona y Deroga Diversas Disposiciones de la Ley Orgánica de la Administración Pública para el Estado de Hidalgo, publicado el 31 de Diciembre de 2016, en el Periódico Oficial del Estado de Hidalgo.",
        "trimestres" : [
            
        ]
    }
]